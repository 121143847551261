<template>
  <div>
    <div>{{ $t('synapses.20240505.byline') }}</div>
    <h1>{{ $t('synapses.20240505.h1_title') }}</h1>
    <p>
      <i18n path="synapses.20240505.p_text_1">
        <template #daily_sudoku>
          <hyperlink :href="$t('hyperlinks.daily_sudoku.href')" :aria-label="$t('hyperlinks.daily_sudoku.purpose')">{{
            $t('globals.text_daily_sudoku')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <div>
      <v-img class="mx-auto my-9" max-height="300" max-width="300" :src="webpExtension(`${settings.cdn.url}coffee.jpg`)" />
    </div>
    <h2>{{ $t('synapses.20240505.h2_title_1') }}</h2>
    <p>{{ $t('synapses.20240505.p_text_2') }}</p>
    <!-- First Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('synapses.20240505.h2_title_2') }}</h2>
    <p>
      <i18n path="synapses.20240505.p_text_3">
        <template #daily_sudoku>
          <hyperlink :href="$t('hyperlinks.daily_sudoku.href')" :aria-label="$t('hyperlinks.daily_sudoku.purpose')">{{
            $t('globals.text_daily_sudoku')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <h2>{{ $t('synapses.20240505.h2_title_4') }}</h2>
    <p>
      <i18n path="synapses.20240505.p_text_5">
        <template #daily_sudoku>
          <hyperlink :href="$t('hyperlinks.daily_sudoku.href')" :aria-label="$t('hyperlinks.daily_sudoku.purpose')">{{
            $t('globals.text_daily_sudoku')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('synapses.20240505.h2_title_5') }}</h2>
    <p>
      <i18n path="synapses.20240505.p_text_6">
        <template #daily_sudoku>
          <hyperlink :href="$t('hyperlinks.daily_sudoku.href')" :aria-label="$t('hyperlinks.daily_sudoku.purpose')">{{
            $t('globals.text_daily_sudoku')
          }}</hyperlink>
        </template>
      </i18n>
    </p>
  </div>
</template>

<script>
import { settings } from '@/config'
import { webp } from '@/mixins'
import { marked } from 'marked'
import Adslot from '@/components/Adslot.vue'
import Hyperlink from '@/components/Hyperlink.vue'

export default {
  name: 'Synapses20240505',
  components: { Adslot, Hyperlink },
  mixins: [webp],
  computed: {
    settings() {
      return settings
    },
  },
  methods: {
    sanitizeHtml(article) {
      return marked(article)
    },
  },
}
</script>

<style lang="scss"></style>
