<template>
  <div>
    <div>{{ $t('synapses.20240623.byline') }}</div>
    <h1>{{ $t('synapses.20240623.h1_title') }}</h1>
    <p>{{ $t('synapses.20240623.p_text_1') }}</p>
    <div>
      <v-img class="mx-auto my-9" max-height="300" max-width="300" :src="webpExtension(`${settings.cdn.url}science.jpg`)" />
    </div>
    <h2>{{ $t('synapses.20240623.h2_title_1') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_2') }}</p>
    <!-- First Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('synapses.20240623.h2_title_2') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_3') }}</p>
    <h2>{{ $t('synapses.20240623.h2_title_3') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_4') }}</p>
    <!-- Second Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('synapses.20240623.h2_title_4') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_5') }}</p>
    <h2>{{ $t('synapses.20240623.h2_title_5') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_6') }}</p>
    <h2>{{ $t('synapses.20240623.h2_title_6') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_7') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-third" ad-class="adslot__content-third" />
    </div>
    <h2>{{ $t('synapses.20240623.h2_title_7') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_8') }}</p>
    <h2>{{ $t('synapses.20240623.h2_title_8') }}</h2>
    <p>{{ $t('synapses.20240623.p_text_9') }}</p>
    <p>{{ $t('synapses.20240623.p_text_10') }}</p>
  </div>
</template>

<script>
import { settings } from '@/config'
import { webp } from '@/mixins'
import { marked } from 'marked'
import Adslot from '@/components/Adslot.vue'

export default {
  name: 'Synapses20240623',
  components: { Adslot },
  mixins: [webp],
  computed: {
    settings() {
      return settings
    },
  },
  methods: {
    sanitizeHtml(article) {
      return marked(article)
    },
  },
}
</script>

<style lang="scss"></style>
