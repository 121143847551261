<template>
  <div>
    <div>{{ $t('synapses.20240421.byline') }}</div>
    <h1>{{ $t('synapses.20240421.h1_title') }}</h1>
    <p>{{ $t('synapses.20240421.p_text_1') }}</p>
    <div>
      <v-img class="mx-auto my-9" max-height="300" max-width="300" :src="webpExtension(`${settings.cdn.url}sudoku-press.jpg`)" />
    </div>
    <h2>{{ $t('synapses.20240421.h2_title_1') }}</h2>
    <p>{{ $t('synapses.20240421.p_text_2') }}</p>
    <div>
      <v-img
        :style="$vuetify.theme.dark ? 'filter: invert(1)' : ''"
        class="mx-auto mt-6"
        max-height="139"
        max-width="300"
        :src="webpExtension(`${settings.cdn.url}lateinisches-quadrat.jpg`)"
      />
      <div class="text-center">{{ $t('synapses.20240421.img_subtitle') }}</div>
    </div>
    <!-- First Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('synapses.20240421.h2_title_2') }}</h2>
    <p>{{ $t('synapses.20240421.p_text_3') }}</p>
    <div v-dompurify-html="sanitizeHtml($t('synapses.20240421.p_text_5'))"></div>
    <h2>{{ $t('synapses.20240421.h2_title_3') }}</h2>
    <p>{{ $t('synapses.20240421.p_text_4') }}</p>
    <!-- Second Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('synapses.20240421.h2_title_4') }}</h2>
    <p>{{ $t('synapses.20240421.p_text_6') }}</p>
    <h2>{{ $t('synapses.20240421.h2_title_5') }}</h2>
    <p>{{ $t('synapses.20240421.p_text_7') }}</p>
    <p>{{ $t('synapses.20240421.p_text_8') }}</p>
  </div>
</template>

<script>
import { settings } from '@/config'
import { webp } from '@/mixins'
import { marked } from 'marked'
import Adslot from '@/components/Adslot.vue'

export default {
  name: 'Synapses20240421',
  components: { Adslot },
  mixins: [webp],
  computed: {
    settings() {
      return settings
    },
  },
  methods: {
    sanitizeHtml(article) {
      return marked(article)
    },
  },
}
</script>

<style lang="scss"></style>
