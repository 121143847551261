<template>
  <div>
    <h1><fa-icon :icon="['fal', 'brain-circuit']" /> {{ $t('views.synapses.h1_title') }}</h1>
    <h2>{{ $t('views.synapses.h2_subtitle') }}</h2>
    <v-divider class="mb-12" />
    <!-- 2024 -->
    <div v-if="$route.path === '/de/synapsen'">
      <v-simple-table class="synapses--table">
        <template #default>
          <tbody>
            <v-hover v-for="(article, index) in articles" :key="`synapsen-article-${index}`" v-slot="{ hover }">
              <Hyperlink :href="article.href" :text-decoration="false">
                <tr :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                  <td>
                    <v-img max-height="150" max-width="150" :src="article.img.src" />
                  </td>
                  <td>
                    <div>{{ article.byline }}</div>
                    <h2 style="font-size: 21px">{{ article.title }}</h2>
                    <p>
                      {{ article.opener }}
                    </p>
                  </td>
                </tr>
              </Hyperlink>
            </v-hover>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <!-- Alle Artikel -->
    <div v-if="false">
      <synapses20240421 class="mb-12"></synapses20240421>
      <v-divider class="mb-12" />
      <div v-dompurify-html="sanitizeHtml(synapses20240411)" class="mb-12"></div>
      <v-divider class="mb-12" />
      <div v-dompurify-html="sanitizeHtml(synapses20240402)" class="mb-12"></div>
      <v-divider class="mb-12" />
      <div v-dompurify-html="sanitizeHtml(synapses20240326)"></div>
    </div>
    <!-- Einzelner Artikel -->
    <div v-if="$route.path.startsWith('/de/synapsen/2024/03/26')">
      <div v-dompurify-html="sanitizeHtml(synapses20240326)" class="mb-12"></div>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/04/02')">
      <div v-dompurify-html="sanitizeHtml(synapses20240402)" class="mb-12"></div>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/04/11')">
      <div v-dompurify-html="sanitizeHtml(synapses20240411)" class="mb-12"></div>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/04/21')">
      <synapses20240421 class="mb-12"></synapses20240421>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/05/05')">
      <synapses20240505 class="mb-12"></synapses20240505>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/05/16')">
      <synapses20240516 class="mb-12"></synapses20240516>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/05/26')">
      <synapses20240526 class="mb-12"></synapses20240526>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/06/08')">
      <synapses20240608 class="mb-12"></synapses20240608>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/06/23')">
      <synapses20240623 class="mb-12"></synapses20240623>
    </div>
    <div v-if="$route.path.startsWith('/de/synapsen/2024/07/21')">
      <synapses20240721 class="mb-12"></synapses20240721>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import { settings } from '@/config'
import { theme, webp } from '@/mixins'
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home.vue'
import Hyperlink from '@/components/Hyperlink.vue'
import { marked } from 'marked'
import synapses20240326 from '../synapses/2024/03/26/entdecke-wie-sudoku-dich-geistig-fit-haelt.md'
import synapses20240402 from '../synapses/2024/04/02/willkommen-in-der-sudoku-knacker-akademie.md'
import synapses20240411 from '../synapses/2024/04/11/ein-offenes-wort-warum-werbung-auf-sudoku-knacker-unverzichtbar-ist.md'
import Synapses20240421 from '../synapses/2024/04/21/Synapses20240421.vue'
import Synapses20240505 from '../synapses/2024/05/05/Synapses20240505.vue'
import Synapses20240516 from '../synapses/2024/05/16/Synapses20240516.vue'
import Synapses20240526 from '../synapses/2024/05/26/Synapses20240526.vue'
import Synapses20240608 from '../synapses/2024/06/08/Synapses20240608.vue'
import Synapses20240623 from '../synapses/2024/06/23/Synapses20240623.vue'
import Synapses20240721 from '../synapses/2024/07/21/Synapses20240721.vue'

export default {
  name: 'Synapses',
  components: {
    Adslot,
    BtnHome,
    Hyperlink,
    Synapses20240421,
    Synapses20240505,
    Synapses20240516,
    Synapses20240526,
    Synapses20240608,
    Synapses20240623,
    Synapses20240721,
  },
  mixins: [theme, webp],
  data() {
    return {
      articles: [
        {
          href: this.$t('hyperlinks.synapses.20240721.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}oasis-of-calm.jpg`),
          },
          byline: this.$t('synapses.20240721.byline'),
          title: this.$t('synapses.20240721.h1_title'),
          opener: this.$t('synapses.20240721.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240623.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}science.jpg`),
          },
          byline: this.$t('synapses.20240623.byline'),
          title: this.$t('synapses.20240623.h1_title'),
          opener: this.$t('synapses.20240623.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240608.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}dutch-miracle.jpg`),
          },
          byline: this.$t('synapses.20240608.byline'),
          title: this.$t('synapses.20240608.h1_title'),
          opener: this.$t('synapses.20240608.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240526.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}phistomefel.jpg`),
          },
          byline: this.$t('synapses.20240526.byline'),
          title: this.$t('synapses.20240526.h1_title'),
          opener: this.$t('synapses.20240526.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240516.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}grandfather.jpg`),
          },
          byline: this.$t('synapses.20240516.byline'),
          title: this.$t('synapses.20240516.h1_title'),
          opener: this.$t('synapses.20240516.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240505.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}coffee.jpg`),
          },
          byline: this.$t('synapses.20240505.byline'),
          title: this.$t('synapses.20240505.h1_title'),
          opener: this.$t('synapses.20240505.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240421.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}sudoku-press.jpg`),
          },
          byline: this.$t('synapses.20240421.byline'),
          title: this.$t('synapses.20240421.h1_title'),
          opener: this.$t('synapses.20240421.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240411.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}frank-conversation.jpg`),
          },
          byline: this.$t('synapses.20240411.byline'),
          title: this.$t('synapses.20240411.h1_title'),
          opener: this.$t('synapses.20240411.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240402.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}academy.jpg`),
          },
          byline: this.$t('synapses.20240402.byline'),
          title: this.$t('synapses.20240402.h1_title'),
          opener: this.$t('synapses.20240402.p_opener'),
        },
        {
          href: this.$t('hyperlinks.synapses.20240326.href'),
          img: {
            src: this.webpExtension(`${settings.cdn.url}gym.jpg`),
          },
          byline: this.$t('synapses.20240326.byline'),
          title: this.$t('synapses.20240326.h1_title'),
          opener: this.$t('synapses.20240326.p_opener'),
        },
      ],
    }
  },
  computed: {
    synapses20240326() {
      return synapses20240326
    },
    synapses20240402() {
      return synapses20240402
    },
    synapses20240411() {
      return synapses20240411
    },
    settings() {
      return settings
    },
  },
  methods: {
    sanitizeHtml(article) {
      return marked(article)
    },
  },
}
</script>

<style lang="scss" scoped>
.synapses--table {
  td {
    vertical-align: top;
    padding: 12px !important;
  }
  tr:hover h2 {
    text-decoration: underline;
  }
}
</style>
