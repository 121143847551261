<template>
  <div>
    <div>{{ $t('synapses.20240608.byline') }}</div>
    <h1>{{ $t('synapses.20240608.h1_title') }}</h1>
    <p>{{ $t('synapses.20240608.p_text_1') }}</p>
    <div>
      <v-img class="mx-auto my-9" max-height="300" max-width="300" :src="webpExtension(`${settings.cdn.url}dutch-miracle.jpg`)" />
    </div>
    <h2>{{ $t('synapses.20240608.h2_title_1') }}</h2>
    <p>{{ $t('synapses.20240608.p_text_2') }}</p>
    <!-- First Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
    </div>
    <h2>{{ $t('synapses.20240608.h2_title_2') }}</h2>
    <p>{{ $t('synapses.20240608.p_text_3') }}</p>
    <ul class="pb-6">
      <li>{{ $t('synapses.20240608.ul_li_text_1') }}</li>
      <li>{{ $t('synapses.20240608.ul_li_text_2') }}</li>
    </ul>
    <h2>{{ $t('synapses.20240608.h2_title_3') }}</h2>
    <p>{{ $t('synapses.20240608.p_text_4') }}</p>
    <!-- Second Content-AdSlot -->
    <div class="text-center my-3">
      <Adslot ad-unit="content-second" ad-class="adslot__content-second" />
    </div>
    <h2>{{ $t('synapses.20240608.h2_title_4') }}</h2>
    <ol class="pb-6">
      <li>{{ $t('synapses.20240608.ol_li_text_1') }}</li>
      <li>{{ $t('synapses.20240608.ol_li_text_2') }}</li>
      <li>{{ $t('synapses.20240608.ol_li_text_3') }}</li>
    </ol>
    <h2>{{ $t('synapses.20240608.h2_title_5') }}</h2>
    <p>{{ $t('synapses.20240608.p_text_5') }}</p>
    <!-- Video -->
    <youtube :video-id="videoId" :player-width="player.width" :player-height="player.height"></youtube>
    <div class="text-center my-4">
      <v-btn :href="hrefYoutube" color="red" rounded large class="my-2 mx-2 white--text font-weight-bold">
        <fa-icon :icon="['fab', 'youtube']" size="2x" class="mr-2" />Auf YouTube ansehen
      </v-btn>
    </div>
    <p>{{ $t('synapses.20240608.p_text_6') }}</p>
    <h2>{{ $t('synapses.20240608.h2_title_6') }}</h2>
    <p>{{ $t('synapses.20240608.p_text_7') }}</p>
    <p>{{ $t('synapses.20240608.p_text_8') }}</p>
    <p>{{ $t('synapses.20240608.p_text_9') }}</p>
  </div>
</template>

<script>
import { settings } from '@/config'
import { webp } from '@/mixins'
import { marked } from 'marked'
import Adslot from '@/components/Adslot.vue'

export default {
  name: 'Synapses20240608',
  components: { Adslot },
  mixins: [webp],
  data() {
    return {
      videoId: 'wUnnXwLTbnA',
      hrefYoutube: 'https://www.youtube.com/watch?v=wUnnXwLTbnA',
    }
  },
  computed: {
    settings() {
      return settings
    },
    player() {
      if (this.breakpoint === 'xs')
        return {
          width: 266,
          height: 150,
        }
      if (this.breakpoint === 'sm')
        return {
          width: '520',
          height: '293',
        }
      if (this.breakpoint === 'md')
        return {
          width: '432',
          height: '243',
        }
      return {
        width: 694,
        height: 390,
      }
    },
  },
  methods: {
    sanitizeHtml(article) {
      return marked(article)
    },
  },
}
</script>

<style lang="scss"></style>
